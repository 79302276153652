



















































































import Component from 'vue-class-component';

import ReportsBanner from '@/components/reports/ReportsBanner.vue';
import ReportsGrid from '@/components/reports/ReportsGrid.vue';
import UiTabs2 from '@/components/ui/UiTabs2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';

import { BaseVue } from '../../BaseVue';

type ReportsSection = { name: string; reports: string[] };

@Component({
  components: {
    UiTextEdit,
    UiTabs2,
    ReportsGrid,
    ReportsBanner,
  },
})
export default class ReportsNew extends BaseVue {
  public readonly tabs = [
    'Home',
    'Standard Reports',
    'Advanced Reports',
    'Inventory View Reports',
    'Audit Reports',
    'Treasury Reports',
    'Custom Reports',
  ];

  public readonly reports = [
    {
      id: 'balance-report',
      title: this.$tc('_balanceReport', 2),
      subtitle: 'Report on the balance of all accounts as of the end of a particular day.',
      to: '/reports/balanceReport',
    },
    {
      id: 'expanded-balance-report',
      title: 'Balance Report (Expanded)',
      subtitle: 'Expanded Report on the balance of all accounts as of the end of a particular day.',
      to: '/reports/expandedBalanceReport',
    },
    {
      id: 'transactions-export',
      title: this.$t('_exportTransactionReport'),
      subtitle: 'Export all transactions in CSV format.',
      to: '/reports/exports',
    },
    {
      id: 'transactions-export-datawarehouse',
      title: this.$t('_exportTransactionReport'),
      subtitle: 'Export all transactions in CSV format.',
      to: '/reports/exports?datawarehouse=true',
    },
    {
      id: 'journal-report',
      title: this.$tc('_journalReport', 2),
      subtitle: 'Export of expected expanded journal entries in target accounting system.',
      to: '/reports/journalReport',
    },
    {
      id: 'expanded-journal-report',
      title: this.$tc('_journalReportExpanded', 2),
      subtitle: 'Export of expected expanded journal entries in target accounting system.',
      to: '/reports/expandedJournalReport',
    },
    {
      id: 'rolled-up-journal-report',
      title: this.$tc('_rolledUpJournalReport', 2),
      subtitle: 'Export of expected journal entries in target accounting system grouped by contacts and categories.',
      to: '/reports/rolledUpJournalReport',
    },
    {
      id: 'ledger-report',
      title: this.$tc('_ledgerReport'),
      subtitle: 'Ledger view of transactions with running balance.',
      to: '/reports/ledgerReport',
    },
    {
      id: 'balance-check-report',
      title: this.$tc('_balanceCheckReport'),
      subtitle: 'Run balance compares from api data to Bitwave data.',
      to: '/reports/balanceCheckReport',
      // enabled: this.checkFeatureFlag('balance-check-enabled'), // How to gate from users in future if needed
    },
    {
      id: 'cost-basis-roll-forward',
      title: 'Valuation (Cost Basis) Rollforward',
      subtitle:
        'Asset by Asset breakdown showing starting balances,  changes and ending balance for both quantity and value (cost basis/carrying value).',
      to: '/inventory/views?view=reports&report=cost-basis-roll-forward',
    },
    {
      id: 'actions',
      title: 'Actions',
      subtitle:
        'A detailed transaction by transaction, lot by lot and action by action view of every transaction including quantities, cost basis, impairment and more.',
      to: '/inventory/views?view=reports&report=actions',
    },
    {
      id: 'reclass',
      title: 'Reclass',
      subtitle:
        'List of journals required to reclassify entries from a consolidated digital assets account to individualized asset accounts.',
      to: '/inventory/views?view=reports&report=je-reclass',
    },
    {
      id: 'actions-je-report',
      title: 'Actions Journal Entry Report',
      subtitle:
        'Journal entries constructed using the actions report. Report requires an error free inventory view to be selected.',
      to: '/inventory/views?view=reports&report=actions-je-report',
      isBeta: true,
    },
    {
      id: 'actions-tb-report',
      title: 'Actions Trial Balance Report',
      subtitle:
        'A trial balance constructed using the inventory view actions report. Report requires an error free inventory view to be selected.',
      to: '/inventory/views?view=reports&report=actions-tb-report',
    },
  ];

  public readonly homeTabSections: ReportsSection[] = [
    {
      name: 'Transaction & Balance',
      reports: ['balance-report', 'expanded-balance-report', 'transactions-export', 'balance-check-report'],
    },
    {
      name: 'Journals',
      reports: [
        'journal-report',
        'expanded-journal-report',
        'rolled-up-journal-report',
        'reclass',
        'actions-je-report',
        'actions-tb-report',
      ],
    },
    {
      name: 'Valuation',
      reports: ['actions', 'cost-basis-roll-forward'],
    },
    {
      name: 'Other',
      reports: ['ledger-report'],
    },
  ];

  public searchValue = '';

  private get searchedReports() {
    return this.reports.filter((report) => {
      return (report.title as string).toLowerCase().includes(this.searchValue.toLowerCase());
    });
  }

  public getReportsOfSection(section: ReportsSection) {
    return this.searchedReports.filter((report) => section.reports.includes(report.id));
  }

  public get standardReports() {
    return this.searchedReports.filter((report) =>
      [
        'balance-report',
        'transactions-export',
        'journal-report',
        'rolled-up-journal-report',
        'balance-check-report',
      ].includes(report.id)
    );
  }

  public get advancedReports() {
    return this.searchedReports.filter((report) =>
      ['expanded-balance-report', 'expanded-journal-report', 'transactions-export-datawarehouse'].includes(report.id)
    );
  }

  public get inventoryViewReports() {
    return this.searchedReports.filter((report) =>
      ['cost-basis-roll-forward', 'actions', 'reclass', 'actions-je-report', 'actions-tb-report'].includes(report.id)
    );
  }

  public get auditReports() {
    return this.searchedReports.filter((report) =>
      ['cost-basis-roll-forward', 'actions', 'balance-check-report'].includes(report.id)
    );
  }

  public get treasuryReports() {
    return this.searchedReports.filter((report) =>
      ['balance-report', 'expanded-balance-report', 'balance-check-report'].includes(report.id)
    );
  }
}
